<template>
  <!-- 虚拟仿真实验 -->
  <div class="con_contain">
    <div class="mainpage">
      <div class="head">
        <div class="searchcontain">
          <input class="elinput" placeholder="请输入虚拟仿真名称" v-model="queryParams.keyword" type="text" />
          <div class="searchbt" @click="searchbt">搜索</div>
        </div>
      </div>

      <div class="titlecategory">
        <div class="titlecategory-item">
          <div class="item-left">学院</div>
          <div class="item-right">
            <div :class="
              queryParams.college_id === ''
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="academybtclick('')">
              全部
            </div>
            <div :class="
              queryParams.college_id === item.id
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="academybtclick(item.id)" v-for="(item, index) in collegelist" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="titlecategory-item">
          <div class="item-left">学科分类</div>
          <div class="item-right">
            <div :class="
              queryParams.subject_id === ''
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="subjectbtclick('')">
              全部
            </div>
            <div :class="
              queryParams.subject_id === item.id
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="subjectbtclick(item.id)" v-for="(item, index) in subjectclassification" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="titlecategory-item">
          <div class="item-left">项目级别</div>
          <div class="item-right">
            <div :class="
              queryParams.level_id === ''
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="itemlevelbtclick('')">
              全部
            </div>
            <div :class="
              queryParams.level_id === item.id
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="itemlevelbtclick(item.id)" v-for="(item, index) in itemlevel" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="titlecategory-item">
          <div class="item-left">排序</div>
          <div class="item-right">
            <div :class="
              queryParams.sort_type == 2
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="sortbtclick(2)">
              加入人数最多
            </div>
            <div :class="
              queryParams.sort_type == 1
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="sortbtclick(1)">
              最新上线
            </div>
            <div :class="
              queryParams.sort_type == 3
                ? 'item-right-text item-right-text-heightlight'
                : 'item-right-text'
            " @click.stop="sortbtclick(3)">
              收藏最多
            </div>
          </div>
        </div>
      </div>
      <div class="image-contain">
        <div class="image-item">
          <div class="image-item-item" @click="todetail(item.id)" v-for="(item, index) in vrlist" :key="index">
            <div class="card-contain">
              <div class="china_class_main">
                <template v-if="item.level == '国家级'">
                  <el-image class="elimage" :src="item.cover" />
                  <div class="china_class">
                    <span>{{ item.level }}</span>
                  </div>
                </template>
                <template v-else-if="item.level == '校级'">
                  <el-image class="elimage" :src="item.cover" />
                  <div class="china_class_1">
                    <span>{{ item.level }}</span>
                  </div>
                </template>
                <template v-else-if="item.level == '省级'">
                  <el-image class="elimage" :src="item.cover" />
                  <div class="china_class_2">
                    <span>{{ item.level }}</span>
                  </div>
                </template>
                <template v-else>
                  <el-image class="elimage" :src="item.cover" />
                </template>
              </div>
              <!-- <el-image class="elimage" :src="randomimage()" /> -->
              <div class="card-title">{{ item.name }}</div>
              <div class="title2">
                <div class="name">{{ item.leader_name }}</div>
                <div class="rightimage">
                  <el-image class="elimage" :src="image3"></el-image>
                  <span class="text">{{ item.join_count }}人参与</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="elpagination">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total" :current-page="queryParams.page"
            :page-size="36" @current-change="pageChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVRList } from "@/api/vr";
import { getcollegelist, gettypeconfiglist } from "@/api/toptype";
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      vrlist: [],
      collegelist: [],
      subjectclassification: [],
      itemlevel: [],
      total: 0,
      queryParams: {
        keyword: "",
        page: 1,
        per_page: 36,
        college_id: "",
        subject_id: "",
        level_id: "",
        sort_type: "2",
      },

      image3: require("@/assets/new/people.png"),
      testimage: require("@/assets/test.png"),
      testimageicon: require("@/assets/矩形图标.png"),
      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
    };
  },
  computed: {},
  mounted() {
    this.getVRList();
    this.getcollegelist({ record_type: 1 }); //record_type 是否获取全部记录 0：分页，1：全部
    this.gettypeconfiglist0({ type: 0 }); //0：学科分类 27：虚拟仿真级别
    this.gettypeconfiglist1({ type: 27 });
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    searchbt() {
      this.queryParams.page = 1;
      this.getVRList();
    },
    academybtclick(id) {
      this.queryParams.college_id = id;
      this.queryParams.page = 1;
      this.getVRList();
    },
    subjectbtclick(id) {
      this.queryParams.subject_id = id;
      this.queryParams.page = 1;
      this.getVRList();
    },
    itemlevelbtclick(id) {
      this.queryParams.level_id = id;
      this.queryParams.page = 1;
      this.getVRList();
    },
    sortbtclick(id) {
      this.queryParams.sort_type = id;
      this.queryParams.page = 1;
      this.getVRList();
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val;
      this.getVRList();
    },
    getcollegelist(params) {
      this.collegelist = [];
      getcollegelist(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              this.collegelist.push({
                id: item.id,
                name: item.name,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gettypeconfiglist0(params) {
      this.subjectclassification = [];
      gettypeconfiglist(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              this.subjectclassification.push({
                id: item.id,
                name: item.name,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gettypeconfiglist1(params) {
      this.itemlevel = [];
      gettypeconfiglist(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item) => {
              this.itemlevel.push({
                id: item.id,
                name: item.name,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVRList() {
      this.vrlist = [];
      getVRList(this.queryParams)
        .then((response) => {
          if (response.data.data && response.data.data.length >= 0) {
            this.total = response.data.total;
            response.data.data.forEach((item) => {
              this.vrlist.push({
                cover: item.cover,
                id: item.id,
                join_count: item.join_count,
                leader_name: item.leader_name,
                level: item.level,
                level_config_id: item.level_config_id,
                name: item.name,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    todetail(id) {
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">




.con_contain {
  display: flex;
  justify-content: center;
  align-items: center;

  .mainpage {
    width: 1760px;

    .head {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .head-title {
        font-size: 20px;
        font-weight: 400;
        color: #288add;
        user-select: none;
        white-space: nowrap;
      }

      .searchcontain {
        position: relative;

        .elinput {
          width: 260px;
          height: 28px;
          border-radius: 25px;
          padding-left: 20px;
          border: 1px solid #3d84ff;

          &:focus {
            outline: none;
          }
        }

        .searchbt {
          position: absolute;
          top: 0px;
          right: 0px;
          margin-left: 17px;
          width: 85px;
          height: 32px;
          background: #3d84ff;
          border-radius: 25px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          user-select: none;

          &:active {
            background: #26b7ffa9;
          }
        }
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }

    .titlecategory {
      margin-top: 20px;
      min-height: 200px;
      background: #fbfbfb;
      border-radius: 4px;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 10px;
      padding-bottom: 5px;

      .titlecategory-item {
        margin-bottom: 30px;
        // border: 1px solid red;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .item-left {
          flex-basis: 80px;
          font-size: 16px;

          font-weight: bold;
          color: #333;
          user-select: none;
          white-space: nowrap;
        }

        .item-right {
          flex-grow: 1;
          flex-shrink: 1;

          .item-right-text {
            display: inline-block;
            margin-bottom: 4px;
            margin-right: 30px;
            font-size: 14px;

            font-weight: 400;
            color: #666;
            user-select: none;
            white-space: nowrap;
            cursor: pointer;
          }

          .item-right-text-heightlight {
            color: #3d84ff;
          }
        }
      }
    }

    .china_class_main {
      position: relative;

      .china_class {
        position: absolute;
        top: 0px;
        right: 0;
        background: #ff0c0c;
        font-size: 12px;
        color: #fff;
        width: 50px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 0px 0px 0px 14px;
      }

      .china_class_1 {
        position: absolute;
        top: 0px;
        right: 0;
        background: #3d84ff;
        font-size: 12px;
        color: #fff;
        width: 50px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 0px 0px 0px 14px;
      }

      .china_class_2 {
        position: absolute;
        top: 0px;
        right: 0;
        background: #FBC248;
        font-size: 12px;
        color: #fff;
        width: 50px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 0px 0px 0px 14px;
      }
    }

    .image-contain {
      margin-top: 10px;

      .image-item {
        margin-bottom: 50px;

        width: 100%;
        overflow: hidden;

        .image-item-item {
          margin-bottom: 10px;
          width: calc((100% - 120px) / 6);
          float: left;
          padding: 10px;
          cursor: pointer;

          .card-contain {
            background: #ffffff;
            border-radius: 4px;

            .elimage {
              display: inline-block;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            }

            .elimage:hover,
            .elimage:focus,
            .elimage:active {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }

            .elimage {
              width: 100%;
              height: 160px;
            }

            .title2 {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .name {
                margin-left: 10px;
                font-size: 12px;
                font-weight: 400;
                color: #666666;
              }

              .rightimage {
                min-width: 150px;

                display: flex;
                justify-content: flex-end;
                align-items: center;

                .elimage {
                  width: 16px;
                  height: 16px;
                }

                .text {
                  margin-right: 10px;
                  margin-left: 4px;
                  font-size: 12px;
                  text-align: left;
                  color: #999;
                }
              }
            }

            .card-title {
              margin-top: 15px;
              margin-left: 10px;
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              width: 95%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }

            .card-footer {
              margin-top: 28px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .icon-title {
                margin-left: 10px;
                font-size: 15px;

                font-weight: 400;
                color: #666666;
              }

              .icon-text {
                background-image: url(~@/assets/矩形图标.png);
                width: 108px;
                height: 24px;
                text-align: center;
                color: #288add;
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
